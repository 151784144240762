import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box, Strong, Em, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aanbod/ontwikkeling"} />
		<Helmet>
			<title>
				dimple - Ontwikkeling
			</title>
			<meta name={"description"} content={"Een digitale werkplek is als een interne website, een \"digitaal kantoor\" waarin je alles terugvindt wat nodig is om productief samen te werken: projecten, klanten, dossiers, documenten, verslagen, planningen, taken, ideeën, evenementen, nieuwsberichten, werkinstructies, medewerker info, tips & trics, ... \n\nOok voor speciale toepassingen kan je bij dimple terecht.  \n\nVoor organisaties, teams en freelancers."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam>
			<Override slot="menu" exact-active-match={false} />
		</Components.MenuHam>
		<Section padding="20px 0 20px 0" sm-padding="60px 0 60px 0" background="#ffffff">
			<Text margin="0px 0px 0px 0px" font="--headline2" text-align="center">
				Ontwikkeling van digitale werkplek op maat{"\n\n"}
			</Text>
			<Text margin="0px 0px 0px 0px" />
		</Section>
		<Section padding="80px 0 40px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 80px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="40%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				/>
				<Image src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_project_team_lc5a.svg?v=2023-05-24T09:30:49.745Z" display="block" max-width="100%" margin="20px 0px 0px 0px" />
				<Box min-width="100px" min-height="100px">
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 0px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 30px 0px"
					>
						<Box>
							<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left" />
							<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
								Een digitale werkplek brengt alles samen wat nodig is om creatief en productief te kunnen zijn in jouw zaak, organisatie of team.{"\n\n"}
								<br />
								<br />
								Een digitale werkplek kan je zien als een keuken.  Het is een ruimte met alle ingrediënten, toestellen en hulpmiddelen die je nodig hebt om een heerlijk gerecht te bereiden.  Mits een doordacht ontwerp, enkele goede gewoontes (alles op zijn plek, afwas gedaan, ...) en betrouwbare recepten wordt koken - alleen of samen - een creatieve en fijne ervaring.{"  "}
								<br />
								Maar het omgekeerde geldt ook: als de snijplank onvindbaar is, het zoutvatje in de garage blijkt te liggen, de kruidenlade te krap, ... verloopt het kook-proces in het beste geval niet erg efficiënt en effectief.  In het slechtste geval erger je je voortdurend blauw en is samen koken simpelweg onmogelijk.
							</Text>
						</Box>
					</Box>
				</Box>
				<Components.TerugNaarAanbod />
				<Components.MaakAfspraak href="mailto:dirk@dimple.be" />
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				/>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="60%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 10px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Voor organisaties en teams{" "}
						</Text>
						<Image
							src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20:52:02.678Z"
							display="block"
							max-width="100%"
							srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20%3A52%3A02.678Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20%3A52%3A02.678Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20%3A52%3A02.678Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20%3A52%3A02.678Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20%3A52%3A02.678Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20%3A52%3A02.678Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/home2.JPG?v=2023-05-29T20%3A52%3A02.678Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							<br />
							Een digitale werkplek is als een interne website, een "
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								digitaal kantoor
							</Strong>
							" waarin je alles terugvindt wat nodig is om productief samen te werken:
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{" "}
							</Em>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								projecten, klanten, dossiers, documenten, verslagen, planningen, taken, ideeën, evenementen, nieuwsberichten, werkinstructies, medewerker info, tips & trics,
							</Strong>
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{" "}...{" "}
								<br />
								<br />
							</Em>
							{" "}Of je nu{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								fysiek{" "}
							</Strong>
							samenwerkt of{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								online
							</Strong>
							.  We ontwerpen een digitale werkplek steeds op maat van jouw behoeften en context.  De inhoud, vorm en functionaliteiten bepalen we samen.  Stap-voor-stap.  Hierbij maken zoveel mogelijk gebruik van{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								best practices en templates
							</Strong>
							.  Die kneden we op maat van jouw specifieke noden en wensen.{" "}
							<br />
							<br />
							Een digitale werkplek brengt rust en overzicht.  Voor jou en je team.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="20px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Voor freelancers en professionals
						</Text>
						<Image
							src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20:50:43.096Z"
							display="block"
							max-width="100%"
							srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20%3A50%3A43.096Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20%3A50%3A43.096Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20%3A50%3A43.096Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20%3A50%3A43.096Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20%3A50%3A43.096Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20%3A50%3A43.096Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dashboard.JPG?v=2023-05-29T20%3A50%3A43.096Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ook als freelancer of professional is een heldere kijk houden op jouw todo's, werkplanning, lopende en aankomende projecten, documentatie, klanten, leveranciers,... niet eenvoudig.  Je hoofd raakt steeks voller met vele op te volgen rode draden met losse eindjes.  Misschien experimenteerde je al met productiviteits-apps of de GTD-aanpak?  Toch bleef het lastig om{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								de voor jou relevante digitale informatie in al zijn vormen
							</Strong>
							{" "}(artikel, verslag, foto, video, idee, taak, quote, contact, site, podcast, bestand...) op een overzichtelijke manier op te slaan, te organiseren en te gebruiken.
							<br />
							{" "}
							<br />
							Een individuele digitale werkplek (aka "
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								second brain
							</Strong>
							") op jouw maat, brengt een oplossing.  Het is een plek waarin alles wat voor jou van waarde is kan aan toevertrouwen.  Het verlicht jouw brein.  Bovendien is het een ideale plek om je persoonlijke "knowledge garden" uit te bouwen.  Daar kan je al jouw ideeën en inzichten laten rijpen, uitwerken en met elkaar verbinden.  Zo kan je aandacht, tijd, energie en talenten richten op het ontwikkelen van jouw zaak en activiteiten.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Specifieke toepassingen
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Je zoekt een oplossing voor een héél{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								specifieke uitdaging
							</Strong>
							: bv. een uitleenkalender voor materialen, een eenvoudige manier om inspirerende links en artikels rond een specifiek thema te delen met sommige van jouw klanten,  een familieforum waar leden makkelijk en veilig meeting verslagen, een kalender en todo-lijst kunnen raadplegen, een samenwerk plek voor één specifiek project, ...{"    "}
							<br />
							Bestaande apps en tools bieden niet wat je zoekt?  Het inhuren van traditionele ontwikkelaars is te duur?  Contacteer dimple!  We gebruiken low-code oplossingen en zijn daardoor bijzonder snel, wendbaar en betaalbaar.{"   "}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Troeven padding="40px 0 80px 0">
			<Override slot="text7">
				Een digitale werkplek is géén doel op zich.   Het is{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					een middel
				</Strong>
				{" "}om mensen te helpen om hun doelen te bereiken. dimple heeft ruime ervaring met het ontwerp en de bouw van gebruikersvriendelijke digitale webapplicaties binnen een professionele context.
			</Override>
			<Override slot="text5">
				dimple ontwikkelt digitale werkplekken{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					afgestemd op jouw doelen, noden en werkcontext
				</Strong>
				{" "}(en niet omgekeerd!).   Een digitale werkplek is gemaakt om mee te groeien met jouw zaak.  Met elke nieuwe uitdaging en behoefte die zich aandient.{"        "}
			</Override>
		</Components.Troeven>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2024 Dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});